<template>
    <div class="search-list-empty">
        <!-- <div  class="search-empty-title">
            <i class="el-icon-warning empty-tip-icon"></i>
            <span>
                抱歉，{{search.length>0?'没有找到与“'+search+'”相关的指标项':'您输入的内容为空'}}，请尝试其他指标项搜索词。
            </span>
        </div> -->
        <div class="related-mode" >
            <div class="related-title">搜一搜</div>
            <ul v-if='type=="econo"' class="related-list clearfix">
                <li>   
                   <el-link :underline="false" href="/#/search?key=地区生产总值">地区生产总值</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search?key=地方财政一般预算收入">地方财政一般预算收入</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search?key=城镇化率">城镇化率</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search?key=社会消费品零售总额">社会消费品零售总额</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search?key=固定资产投资">固定资产投资</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search?key=进出口">进出口</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search?key=企业法人单位数">企业法人单位数</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search?key=供水总量">供水总量</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search?key=居民人均消费支出">居民人均消费支出</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search?key=人口">人口</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search?key=平均工资">平均工资</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search?key=年末人数">年末人数</el-link> 
                </li>
            </ul>
            <ul v-else-if='type=="ecoIndustry"' class="related-list clearfix">
                <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=建筑业总产值">建筑业总产值</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=资产负债率">资产负债率</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=流动比率">流动比率</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=从业人员">从业人员</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=进出口总额">进出口</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=利润总额">利润总额</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=营业收入">营业收入</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=资产总计">资产总计</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=房地产">房地产</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=企业法人单位数">企业法人单位数</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=利润率">利润率</el-link> 
                </li>
                <li>   
                   <el-link :underline="false" href="/#/search/ecoIndustrySearch?key=学校数">学校数</el-link> 
                </li>
            </ul>
            <ul v-else-if='type=="microeco"' class="related-list clearfix">
                <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=住宅房价">住宅房价</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=住宅租金">住宅租金</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=商铺房价">商铺房价</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=商铺租金">商铺租金</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=平均工资">平均工资</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=招聘条目数">招聘条目数</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=螺纹钢">螺纹钢</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=汽油">汽油</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=焦煤">焦煤</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=水泥">水泥</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/microEcoSearch?key=大豆">大豆</el-link> 
                </li>
                <li>   
                   <el-link :underline="false" href="/#/search/microEcoSearch?key=白菜">白菜</el-link> 
                </li>
            </ul>
            <ul v-else-if='type=="ecoArticle"' class="related-list clearfix">
                <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=工业企业经济效益">工业企业经济效益</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=社会消费品零售总额">社会消费品零售总额</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=居民消费价格">居民消费价格</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=房地产">房地产</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=就业人员">就业人员</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=国民经济">国民经济</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=行业发展">行业发展</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=固定资产投资">固定资产投资</el-link> 
                </li>
                <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=外商">外商</el-link> 
                </li>
                 <li>   
                    <el-link :underline="false" href="/#/search/ecoArticleSearch?key=农业">农业</el-link> 
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
     props: {
        search: String,
        type:String
    },
    setup() {
        
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.search-list-empty{
    width: 60%;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 8px 8px 20px rgb(55 99 170 / 10%);
}
.search-empty-title {
    font-size: 18px;
    line-height: 34px;
    padding-top: 40px;
    padding-bottom: 60px;
    text-align: center;
    .empty-tip-icon {
        color: #ff6a00;
        font-size: 34px;
        line-height: 34px;
        vertical-align: bottom;
        margin-right: 15px;
    }
}
.related-mode{
    margin: 0 5%;
}
.related-mode .related-title {
    color: $specialtext-color;
    letter-spacing: .16px;
    line-height: 24px;
    margin-left: 5px;
}
.related-mode .related-list li{
    float: left;
    width: 25%;
    text-align: left;
    line-height: 40px;
    padding:0 5px;
    a{
        font-size: 16px;
    }
}

</style>