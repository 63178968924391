<template>
    <div style="display:inline-block;">
        <el-tooltip content="收藏" effect='light' placement="bottom-start">
            <i class="iconfont ec-icon-star-on fav-i" :class="{'fav-i':!isFavorite,'sel-fav-i':isFavorite}" @click="handleSelect()"></i>
        </el-tooltip>

        <el-dialog v-model="dialogTableVisible" destroy-on-close :close-on-press-escape='false' title="添加收藏夹" @close="isAddFavorite = false" >
            <div>
                <!-- <div class="folder">
                    
                    <div class="input" @click.stop="btnAdd()" :style="{'display':isInput?'block':'none'}">
                        <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="点击弹窗内其他区域，取消新建收藏夹"
                        placement="top-start" trigger='focus'>
                            <input ref="refInput" type="text" maxlength="20" placeholder="最多可输入20个字" v-model='favoritesName'>
                        </el-tooltip>
                        <el-button @click="btnAddFavorites"><i class="iconfont ec-icon-circle-plus-outline" style="margin-right:4px;"  ></i>新建</el-button>
                    </div>
                </div>
                -->
                <span class="btn"  v-show="!isAddFavorite" @click.stop="btnAdd()" ><i class="iconfont ec-icon-circle-plus-outline"></i>新建文件夹</span>
                <!-- 新建收藏夹 -->
                <div class="folder" v-show='isAddFavorite'>
                    <el-input ref="refInput" v-model="favoritesName" maxlength="20" placeholder="收藏夹标题" show-word-limit  type="text" />
                    <p class="msg">{{msg}}</p> 
                    <div style="margin-top:40px;text-align:right;">
                        <el-button @click="isAddFavorite=false" type="text">返回</el-button>
                        <el-button @click="btnAddFavorites" type="primary">新建</el-button>
                    </div>
                </div>
                <el-scrollbar v-show='!isAddFavorite' class="items">
                    <ul  >
                        <li v-for="item in isFavoriteList" class="item" :key="item.favId" :class="{'collected':item.isFavorite}" @click="isFavoriteFun(item.favId,item.isFavorite)">
                            <span>{{item.favName}}</span>
                            <span class="collect-btn">{{item.isFavorite?'已收藏':'收藏'}}</span>
                        </li>
                    </ul>
                </el-scrollbar>
            </div>
        </el-dialog>
        
    </div>
</template>
<script>
import {getItemIsFavorite,addUserFavorites,addItemOfFavorites,delItemOfFavorites,getUserRightFun,addUserLog} from '@/http/basicsApi.js'
import { ref } from '@vue/reactivity';
import { nextTick, watch } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
     props: {
        isChange:{
            type:String
        },
        name:{//收藏按钮的名称
            type:String
        },
        itemId: {//指标项ID
            type: String,
        },
        objId: {//对象ID
            type: String,
            default:''
        }
  },
  setup(props) {
        console.log(props);
        const route =useRoute(); //useRoute();//当前路由
        let dialogTableVisible=ref(false);//对话框的呈现
        let isFavoriteList=ref(null);//指标项是否收藏列表
        let isFavorite=ref(false);//指标项是否收藏
        let isInput=ref(false);//新建收藏夹文本框是否显示
        let isAddFavorite=ref(false);//是否显示新建收藏夹
        const refInput =ref()
        let favoritesName=ref('');//收藏夹名称
        let msg=ref('');//创建收藏夹时的提示消息
        watch(
            ()=>props.isChange,
            (val,prevVal)=>{
                console.log(val);
                console.log(prevVal);
                getItemIsFavoriteFun();
            },
            {deep:true}
        )
        const handleSelect=async ()=>{
            //判断是否有权限
            if(await getUserRightFun(props.name,1,route.meta.menuId)){
                dialogTableVisible.value = true
            }
            
        }
        const btnAdd=()=>{
            isAddFavorite.value = true; 
            favoritesName.value=''
            msg.value=''
            
            nextTick(()=>{
                refInput.value.focus()
            })
        }
        //创建收藏夹
        const btnAddFavorites=()=>{
            console.log(favoritesName.value);
            //判断收藏夹是否为空
            if(favoritesName.value.length<=0){
                msg.value="名称不能为空"
            }
            //判断收藏夹名称长度
            else if(favoritesName.value.length>20 || favoritesName.value.length<=2){
                msg.value="名称在2到20个子之间";
            }
             //判断收藏夹是否存在
            else if(isFavoriteList.value.filter(t=>t.favName==favoritesName.value).length>0){
                msg.value="名称已存在";
            }
            else{
                //添加收藏夹
                addUserFavoritesFun();
            }
        }
        //指标项是否已收藏
        const  getItemIsFavoriteFun= async()=>{
            let param={
                itemId:props.itemId,
                objId:props.objId
            }
            console.log(param);
            const result= await getItemIsFavorite(param);//当前时间地区指标项值
            console.log(result);
            if(result.code==20000){
                isFavoriteList.value=result.data;
                let filterAry=result.data.filter(t=>t.isFavorite==1);
                if(filterAry.length>0)
                {
                    isFavorite.value=true;
                }
                else{
                     isFavorite.value=false;
                }   
            }
        }
        //添加收藏夹
        const addUserFavoritesFun=async()=>{
            let param={
                name:favoritesName.value
            }
             const result= await addUserFavorites(param);//当前时间地区指标项值
             if(result.code==20000){
                 //重新加载收藏夹列表
                getItemIsFavoriteFun();
                isAddFavorite.value=false;
             }
             console.log(result);
        }
        //点击收藏夹进行收藏或取消收藏
        const isFavoriteFun=(favId,isFavorite)=>{
            if(isFavorite){//对已收藏的指标进行取消收藏
                delItemOfFavoritesFun(favId);
            }
            else{//对未收藏的指标项进行收藏
                addItemOfFavoritesFun(favId);
            }
        }
        // //添加收藏夹指标项
        const addItemOfFavoritesFun=async(favId)=>{
            let param={
                favId:favId,
                itemId:props.itemId,
                objId:props.objId
            }
            //console.log(param);
            const result= await addItemOfFavorites(param);//当前时间地区指标项值
            if(result.code==20000){
                //添加收藏夹指标项
                dialogTableVisible.value=false;
                //重新加载
                getItemIsFavoriteFun();
            }
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'收藏',JSON.stringify(param));
        }
        // 删除收藏夹指标项
        const delItemOfFavoritesFun=async(favId)=>{
            let param={
                favId:favId,
                itemId:props.itemId,
                objId:props.objId
            }
            const result= await delItemOfFavorites(param);//当前时间地区指标项值
            if(result.code==20000){
                dialogTableVisible.value=false;
                //重新加载
                getItemIsFavoriteFun();
            }
            console.log(result);
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'取消收藏',JSON.stringify(param));
        }
        //getItemIsFavoriteFun()
        return{
            dialogTableVisible,
            isFavoriteList,
            isFavorite,
            favoritesName,
            refInput,
            isAddFavorite,//是否显示新建收藏夹
            isInput,
            btnAdd,
            btnAddFavorites,
            msg,
            isFavoriteFun,
            handleSelect

        }
  }
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.folder {
   
    padding: 0px 20px;
    
}

.btn{
    height: 100%;
    display: block;
    cursor: pointer;
    font-weight: 500;
    height: 48px;
    background: #F8F8F8;
    line-height: 48px;
    border-radius: 2px;
    margin: 0 20px;
}
.btn:hover {
    background-color: #f5f6f7;
}
.btn i {
    display: inline-block;
    margin: 0 6px;
    vertical-align: middle;
    position: relative;
    color: $specialtext-color;
}
.input {
    display: none;
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-top: 4px;
    border-radius: 20px;
    border: 1px solid #ccccd8;
    font-size: 0;
    overflow: hidden;
    padding-left: 10px;
}
.input input {
    font-size: 14px;
    display: inline-block;
    width:  calc(100% - 118px);
    height: 40px;
    line-height: inherit;
    padding-left: 16px;
    outline: 0;
    border: 0 none;
}
.input button {
    font-size: 14px;
    display: inline-block;
    width: 100px;
    height: 40px;
    outline: 0;
    font-weight: 500;
}
.msg {
    color: #e33e33;
    position: relative;
    top: 0;
    font-size: 12px;
    margin: 0;
}
.items {
    font-size: 14px;
    font-weight: 400;
    padding: 0 16px;
    height: 298px;
    overflow: auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
}
.item {
    width: 100%;
    height: 48px;
    line-height: 48px;
    position: relative;
    cursor: pointer;
    padding-left: 32px;
}
.item:hover {
    background: #f5f6f7;
}
span.collect-btn {
    float: right;
    margin-top: 10px;
    margin-right: 8px;
    color: #555666;
    font-size: 14px;
    width: 64px;
    height: 28px;
    line-height: 26px;
    border-radius: 20px;
    text-align: center;
    border: 1px solid #ccccd8;
}
.item.collected span.collect-btn {
    color: #fc5531;
    border: 1px solid #fc5531;
}
</style>